import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AccordionSection extends Component {

    static propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
        isOpen: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    onClick = () => {
        this.props.onClick(this.props.label);
    };

    render() {
        const {
            onClick,
            props: { isOpen, label },
        } = this;

        return (
            <div
                className={"accordion-wrapper " + (isOpen ? "open" : "closed")}
            >
                <div
                    className={"title " + (isOpen ? "open" : "closed")}
                    onClick={onClick}
                    onKeyDown={onClick}
                    style={{ cursor: 'pointer' }}
                    role="button"
                >
                    {label}
                    <div style={{ float: 'right' }}>
                        <span className={"symbol " + (isOpen ? "open" : "closed")}><span>&#x2b;</span></span>
                    </div>
                </div>
                <div
                    className={"accordion-body " + (isOpen ? "open" : "closed")}

                >
                    {this.props.children}
                </div>

            </div>
        );
    }
}

export default AccordionSection;