import React from "react";

const TourVideo = () => (
    <video
        width="100%"
        height="auto"
        controls
        poster="/videos/Element-Physiotherapy-Location-THUMBNAIL.jpg"
        title="A short video showing you how to locate Element Physiotherapy"
    >
        <source src="/videos/Element-Physiotherapy-Location.webm" type="video/webm" />
        <source src="/videos/Element-Physiotherapy-Location.ogg" type="video/ogg" />
        <source src="/videos/Element-Physiotherapy-Location.mp4" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
)

export default TourVideo;
