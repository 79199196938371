import React from "react";
import TourVideo from "../components/TourVideo"

import InternalLayout from "../layouts/internallayout";
import styled from "styled-components";
import Accordion from '../components/Accordion';
import Seo from "../components/seo";

const Container = styled.div`
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding: 5em 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
`

const DesktopTwoColumns = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    @media (min-width: 768px){
        flex-direction: row;
       
       > div{
        width: 50%;
       } 
        
    }
`

const Faqs = () => (
    <InternalLayout>
        <Seo title="Frequently Asked Questions" />
        <Container>
            <h1>Frequently Asked Questions</h1>
            <Accordion>

                <div label='Do I need a doctor’s referral to visit Element Physio?'>
                    <p>
                    You do not need a referral from your doctor unless your injury is related to a Workers’ Compensation claim, a Motor Vehicle Accident, or you are eligible to claim under the Department of Veterans’ Affairs.
                    </p>

                </div>

                <div label='Are your physiotherapists experienced and qualified?'>
                    <p>
                    All of our physiotherapists have excellent clinical and communication skills and are regularly involved in ongoing education and upskilling in professional face to face course work. Their knowledge is shared between each therapist so you can be confident that the treatment you receive from your physiotherapists is at the forefront of physiotherapy treatment.
                    </p>
                    <p>
                    Our team work together in a collaborative approach to patient care. So if your therapist is away on leave, you can be seen by any member of our physiotherapy team, and know that your management will continue as normal. We recommend that if you are in pain – do not wait for your therapist to return from leave – it is important to call us and organise treatment for as soon as possible.
                    </p>

                </div>

                <div label='What should you expect at your initial appointment?'>
                    <p>
                    We offer and recommend our new patients have a 1 hour initial appointment so we can hear your story, conduct a thorough examination, explain to you what is going on, treat you, and set up a management plan. We want to empower you towards recovery in a way that is easy for you to understand.
                    </p>
                    <p>
                    If your condition has been a complex and long-term injury we strongly recommend a 1 hour initial appointment.
                    </p>
                    <p>
                    If you feel that your condition is relatively new, or perhaps a simple sprain, then a 30 minute initial appointment may be more suitable for you. Please speak to our reception team for guidance.
                    </p>
                    <p>
                    In summary:
                    </p>
                    <ul>
                        <li>Arrive early to fill out forms</li>
                        <li>Expect to be there for an hour</li>
                        <li>Wear comfortable, loose clothing</li>
                        <li>Be prepared to do movement tests that may hurt a little initially</li>
                        <li>Treatment will be initiated at the end of your assessment</li>
                        <li>It will be normal to be a little sore for a day after your treatment</li>
                        <li>Work with your physiotherapist as they are now on your team</li>
                    </ul>

                </div>

                <div label='Where are you located?'>
                    <p>
                    Ground Floor, Element Building, 200 Central Coast Hwy, Erina, NSW, 2250. Carparking is available via Karalta Lane.
                    </p>
                    <DesktopTwoColumns>
                        <div>
                            <TourVideo
                            videoSrcURL="https://www.youtube.com/embed/xely74mbdpc"
                            videoTitle="Official Music Video on YouTube"
                        />
                        </div>
                        <div class="video-wrapper">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.540321755411!2d151.38628029999998!3d-33.4352268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b72ca70beb9a2c5%3A0x1770b3a9f572a4bd!2sErina%20Physiotherapy%20Sports%20and%20Exercise%20Rehabilitation%20Centre!5e0!3m2!1sen!2sau!4v1571909921709!5m2!1sen!2sau" frameborder="0" allowfullscreen=""></iframe>
                        </div>
                    </DesktopTwoColumns>

                </div>

                <div label='Is there parking available?'>
                    <p>
                    Easy access and undercover parking is available to the Element Building via Karalta Lane.
                    </p>

                </div>

               <div label='How quickly can I be seen by a physio?'>
                <p>
                    The time can vary but if we are unable to find you an appointment in the time frame you require, we do have a waiting list which is reviewed daily. We will endeavour to find you an earlier appointment depending on your flexibility with dates and times.
                    </p>
                    <p>
                    Make a booking now
                    </p>

                </div>

                <div label='What are your opening hours?'>
                    <p>
                    Our normal opening hours are 8.00am to 6.00pm. Please refer to ‘Contact Us’ for all opening hours.
                    </p>

                </div>
                <div label='Are you open on Saturdays?'>
                    <p>
                    Yes, our clinic opens Saturdays from 8.00am to 12.00pm for your convenience.
                    </p>


                </div>
                <div label='What should I bring to my first appointment?'>
                    <p>
                    You may need to bring:
                    </p>

                    <ul>
                        <li>Your doctors/specialist’s referral</li>
                        <li>Previous scans/imaging/reports</li>
                        <li>Wear comfortable clothing and footwear</li>
                        <li>Water/drink bottle</li>
                    </ul>

                </div>
                <div label='What should I wear to my appointment?'>
                    <p>
                        Prior to your first physio appointment, we recommend you think through the area’s we will assess and restrictions you have.
                    </p>
                    <p>
                        We recommend you prepare by wearing comfortable, loose-fitting clothing that will allow us to easily see the skin, joints and muscles around the area to be assessed.
                    </p>
                    <p>
                        In the cooler months, a singlet underneath a jumper or shorts underneath pants work very well.
                    </p>
                    <p>
                        In the warmer months,  a loose-fitting top or shorts makes our assessment much easier.
                    </p>
                    <p>
                        Sneakers, trainers, running shoes are the best footwear, as they offer great support for exercises and can be easily removed.
                    </p>

                </div>
                <div label='Do you conduct medical imaging?'>
                    <p>
                        Our physiotherapists will advise you if medical imaging is necessary. You will be referred to a medical imaging company if required.
                    </p>

                </div>
                <div label='How long will a physio appointment last?'>
                <ul>
                    <li>Initial - 45 to 60mins</li>
                </ul>
                    <p>
                        Over many years of treating thousands of patients, we believe that having a bit of extra time when we first meet you allows a few good things to happen:
                    </p>
                    <p>
                        You have time to tell us your story right from the beginning which generally provides us with crucial information.
                    </p>
                    <p>
                        We are able to carefully and specifically diagnose your movement issues, discuss our diagnosis with you, formulate a treatment plan, and provide treatment on your first visit.
                    </p>
                    <p>
                        Having only 30 mins for your first appointment can be too rushed, and we risk missing valuable information either from you or your body if time is limited.
                    </p>
                    <p>
                        In saying that, for a simple ankle or wrist injury, with no other body part involvement, our front desk staff will be able to allocate 30 mins for your initial appointment.
                    </p>
                    <ul>
                        <li>Follow-up -  30mins</li>
                    </ul>

                    </div>
                    <div label='How many appointments will I have to attend?'>
                        <p>
                            This will be determined by your physio once they have assessed you. This will be done in consultation with you and the need for attendance will be explained to you. Rest assured, we will always look to treat you as little as possible and empower you to recover as soon as possible.
                        </p>
                    </div>
            </Accordion>

        </Container>
    </InternalLayout>
)

export default Faqs

